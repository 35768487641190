<template>
  <div class="content">
    <moreHeader></moreHeader>
    <div class="title-box">
      <div class="title-name">污水处理</div>
      <div class="title-p">
        水体污染和富营养化危及水生态系统、水环境状况的恶化已成为建设生态
        文明及绿色发展的短板，我国水生态环境状况不容乐观
      </div>
    </div>
    <div class="function">
      <div class="scenes-box">
        <h3>方案优势</h3>

        <div class="scenes-bg">
          <div>
            <div class="leftTop">
              <img
                class="icons"
                src="../../assets/assets/sewage/ssx.png"
                alt=""
              />
              实时性
            </div>
            <div class="targetContent">
              系统无时延，无需轮巡就可以同步接收、处理多个/所有数据采集点的数据。可很好的满足系统对数据采集和传输实时性的要求。
            </div>
          </div>

          <div>
            <div class="leftTop">
              <img
                class="icons"
                src="../../assets/assets/sewage/yckz.png"
                alt=""
              />
              远程控制
            </div>
            <div class="targetContent">
              通过网络双向系统还可实现对视频设备进行远程控制，进行参数调整、转动云台等控制操作。
            </div>
          </div>
          <div>
            <div class="leftTop">
              <img
                class="iconss"
                src="../../assets/assets/sewage/cbd.png"
                alt=""
              />
              成本低
            </div>
            <div class="targetContent">
              由于采用现有网络或3G无线网络，只需安装好设备就可以，不需要进行专门布线，前期投资少、见效快，后期升级、维护成本低；
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="function">
      <h3>平台架构</h3>
      <div class="services">
        <div class="left">
          <img
            style="width: 100%; height: 100%"
            @click="onYuLan(img)"
            :src="img"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="function">
      <h3>功能场景</h3>
    </div>
    <div class="scene">
      <div class="img1">
        <div class="sceneBox">
          <div class="sceneIcon icon1"></div>
          <div class="sceneTitle">资产保养</div>
          <div class="sceneContents">
            对设备和备件等资产台账管理，提供完善的各类报表。实现了对生产设备的科学化、规范化、信息化的管理，延长了设备使用寿命和提高设备的使用效率。
          </div>
        </div>
      </div>

      <div class="img2">
        <div class="sceneBox">
          <div class="sceneIcon icon2"></div>
          <div class="sceneTitle">工艺监控</div>
          <div class="sceneContents">
            本系统根据生产工艺流程将各种设备实时运行状况等信息进行图形化实时监视，生产过程中出现异常过程实时告警，从而极大的增强了生产运营的稳定性。
          </div>
        </div>
      </div>
      <div class="img3">
        <div class="sceneBox">
          <div class="sceneIcon icon3"></div>
          <div class="sceneTitle">辅助决策</div>
          <div class="sceneContents">
            本系统提供多种报表统计功能。对各阶段、各时期、各类生产运行数据可进行统计，报表分析，并以直观的图表形式形式，如历史生产数据报表，对辅助管理者的决策提供强大的支持。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moreHeader from "../../components/header";
import { ImagePreview } from "vant";
export default {
  components: { moreHeader },
  name: "",
  data() {
    return { img: require("../../assets/image/sewage/jiagou.png") };
  },

  methods: {
    onYuLan(img) {
      console.log(img);
      ImagePreview([img]);
    },
  },
};
</script>

<style scoped lang="less">
.content {
  box-sizing: border-box;
  margin-top: -27px;
  h3 {
    text-align: center;
    padding: 20px 0;
    letter-spacing: 5px;
    background: #fff;
    margin: 0;
    font-size: 18px;
  }
  .title-box {
    padding-top: 100px;
    width: 100%;
    color: #fff;
    height: 480px;

    background: url("../../assets/assets/sewage/mwushuibg.png") no-repeat;
    background-size: 100% 100%;
    .title-name {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      transform: translateY(30px);
      text-align: center;
      letter-spacing: 2px;
      padding-top: 60px;
    }
    .title-p {
      padding: 0 20px;
      font-size: 16px;

      transform: translateY(45px);
      color: #ffffff;
      text-align: left;
      letter-spacing: 2px;
      line-height: 20px;
    }
  }

  .function {
    // background-color: #fff;

    .scenes-box {
      padding-top: 10px;
      background-color: #fff;

      .scenes-bg {
        padding: 0 10px;

        // height: 170px;

        > div {
          width: 100%;
          //   height: 100%;
          height: 150px;
          background: url("../../assets/assets/wisdomWater/mbbj.png") no-repeat;
          background-size: 100% 100%;
          margin-top: 10px;
          .leftTop {
            width: 38%;
            height: 20px;
            line-height: 20px;
            text-align: left;
            font-size: 18px;
            color: #fff;
            font-weight: 400;
            border-bottom-right-radius: 13px;

            background-color: #c7b090;
            padding: 10px 0;
          }
          .targetContent {
            // margin: 10px;
            color: #6b6b6b;
            line-height: 18px;
            font-size: 16px;
            padding: 15px;
          }
        }
        .icons {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-left: 10px;
        }
        .iconss {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-bottom: 3px;
          margin-left: 10px;
        }
      }
      .scenes-text {
        width: 100%;
        height: 200px;
        text-align: center;
        background-color: #fff;
        .title {
          font-size: 14px;
          font-weight: 600;
          padding: 10px 0;
          color: #333333;
        }
        .text-box {
          display: flex;
          .text-box-item {
            width: 16%;
            font-size: 16px;
            color: #6b6b6b;
            margin-top: 30px;
            border-right: 1px solid #dfdfdf;
            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .services {
      padding: 0 10px;

      background-color: #fff;
      .left {
        width: 100%;
        height: 300px;
        // padding-top: 4%;
        background: url("../../assets/image/sewage/jiagou.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .nh-text {
      font-size: 14px;
      margin: 10px 0;
      color: #6b6b6b;
    }
  }
}
.scene {
  padding: 0 10px;
  margin-bottom: 10px;
  //   overflow: hidden;
  .sceneBox {
    position: relative;
    margin: 5px 0;

    .sceneContents {
      padding: 5px 15px;
      color: white;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }

    .sceneIcon {
      width: 42px;
      height: 42px;
      margin-top: 25px;
    }
    .sceneTitle {
      position: relative;
      padding-top: 80px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin: 5px 0;
      opacity: 1;

      box-sizing: border-box;
      padding-bottom: 10px;
      text-align: center;
      //   border-bottom: 2px solid #fff;
      &::before {
        position: absolute;
        content: "";
        width: 120px;
        height: 1px;
        background-color: #fff;
        bottom: 1%;
        left: 50%;
        transform: translate(-50%, 50%);
      }
    }
    .sceneContent {
      color: #fff;
      margin: 10px 20px;
      z-index: 99;
      display: none;
    }
  }
}
.img1 {
  height: 210px;
  background: url("../../assets/assets/sewage/mzcbybg.png") no-repeat;
  background-size: 100% 100%;
  // margin: 0 5%;
}
.img2 {
  height: 210px;
  background: url("../../assets/assets/sewage/mgyjkbg.png") no-repeat;
  background-size: 100% 100%;
  //  margin: 0 5%;
}
.img3 {
  height: 210px;
  background: url("../../assets/assets/sewage/mfzjcbg.png") no-repeat;
  background-size: 100% 100%;
  //  margin: 0 5%;
}

.icon1 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: url("../../assets/assets/sewage/zcbyicon.png") no-repeat;
  background-size: 100% 100%;
}
.icon2 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: url("../../assets/assets/sewage/gyjkicon.png") no-repeat;
  background-size: 100% 100%;
}
.icon3 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
  background: url("../../assets/assets/sewage/fzjcicon.png") no-repeat;
  background-size: 100% 100%;
}
</style>
